import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

function HlsPlayer(props) {
    return (
        <ReactHlsPlayer
            autoPlay={false}
            controls={true}
            width="100%"
            height="auto"
            src={`https://download.ethicsgradient.space/${props.video}/hls.m3u8`}        />
    );
}
export default HlsPlayer;
//src={`http://localhost:5000/static/${props.video}/hls.m3u8`}        />