import React, {useState} from 'react';
import './App.css';
import HlsPlayer from "./HlsPlayer";
import {
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function App() {
  let query = useQuery();
  const [video, setVideo] = useState(query.get("id"));

    
  return (
    <div className="App">
        <h1>Video Player</h1>
        Video:
            <input
                name='name'
                type='text'
                value={video ? video : ''}
                onChange={e => setVideo(e.target.value)}
            />
            <br />
      <HlsPlayer video={video}/>
    </div>
  );
}

export default App;
